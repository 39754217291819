import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { ApiError, ApiFetchStatus } from "../../utils/Api"

interface GroupToCreate {
  name: string
  color: string
  participants: string[] | []
}

export interface Group extends GroupToCreate {
  id: string
  creatoremail: string
  creatorname: string
}


interface State {
  groups: Group[]
  fetchGroupsStatus: ApiFetchStatus
  fetchCreateGroupStatus: ApiFetchStatus
  fetchCreateGroupError?: string
  fetchModifyGroupStatus: ApiFetchStatus
  fetchModifyGroupError?: string
  fetchDeleteGroupStatus: ApiFetchStatus
  fetchDeleteGroupError?: string
}

const initialState: State = {
  groups: [],
  fetchGroupsStatus: "idle",
  fetchCreateGroupStatus: "idle",
  fetchModifyGroupStatus: "idle",
  fetchDeleteGroupStatus: "idle",
}


export const fetchGroups = createAsyncThunk(
  'organizationGroups/fetchGroups',
  async (organizationId: string, { getState }) => {
    const state = getState() as any

    const response = await fetch(`${process.env.REACT_APP_BASE_USERS_URL}/organization/${organizationId}/group/list`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${state.auth.token}`,
      },
    })
    
    if (!response.ok) {
      const reason = (await response.json()).reason || "unknown_error"
      throw new ApiError(reason)
    }

    return await response.json()
  }
)


export const fetchCreateGroup = createAsyncThunk(
  'organizationGroups/fetchCreateGroup',
  async (
    { organizationId, group }: { organizationId: string, group: GroupToCreate }, 
    { getState }
  ) => {
    const state = getState() as any

    const response = await fetch(`${process.env.REACT_APP_BASE_USERS_URL}/organization/${organizationId}/group/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': state.auth.token,
      },
      body: JSON.stringify(group),
    })

    if (!response.ok) {
      const reason = (await response.json()).reason || "unknown_error"
      throw new ApiError(reason)
    }
  }
)

export const fetchModifyGroup = createAsyncThunk(
  'organizationGroups/fetchModifyGroup',
  async (
    { organizationId, groupId, group }: { organizationId: string, groupId: string, group: GroupToCreate },
    { getState }
  ) => {
    const state = getState() as any

    const requestBody = {
      groupId: groupId,
      ...group,
    }

    const response = await fetch(`${process.env.REACT_APP_BASE_USERS_URL}/organization/${organizationId}/group/modify`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': state.auth.token,
      },
      body: JSON.stringify(requestBody),
    })

    if (!response.ok) {
      const reason = (await response.json()).reason || "unknown_error"
      throw new ApiError(reason)
    }
  }
)

export const fetchDeleteGroup = createAsyncThunk(
  'organizationGroups/fetchDeleteGroup',
  async (
    { organizationId, groupId }: { organizationId: string, groupId: string },
    { getState }
  ) => {
    const state = getState() as any

    const response = await fetch(`${process.env.REACT_APP_BASE_USERS_URL}/organization/${organizationId}/group/delete`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': state.auth.token,
      },
      body: JSON.stringify({ groupId }),
    })
    
    if (!response.ok) {
      const reason = (await response.json()).message || "unknown_error"
      throw new ApiError(reason)
    }
  }
)

const organizationGroupsSlice = createSlice({
  name: 'organizationGroups',
  initialState,
  reducers: {
    idleOrganizationGroups: (state) => {
      state.fetchGroupsStatus = "idle"
      state.fetchCreateGroupStatus = "idle"
      state.fetchCreateGroupError = undefined
      state.fetchModifyGroupStatus = "idle"
      state.fetchModifyGroupError = undefined
      state.fetchDeleteGroupStatus = "idle"
      state.fetchDeleteGroupError = undefined
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchGroups.pending, (state) => {
        state.fetchGroupsStatus = "loading"
      })
      .addCase(fetchGroups.fulfilled, (state, { payload }: PayloadAction<Group[]>) => {
        state.fetchGroupsStatus = "success"
        state.groups = payload
      })
      .addCase(fetchGroups.rejected, (state) => {
        state.fetchGroupsStatus = "error"
      })
      .addCase(fetchCreateGroup.pending, (state) => {
        state.fetchCreateGroupStatus = "loading"
      })
      .addCase(fetchCreateGroup.fulfilled, (state) => {
        state.fetchCreateGroupStatus = "success"
      })
      .addCase(fetchCreateGroup.rejected, (state) => {
        state.fetchCreateGroupStatus = "error"
      })
      .addCase(fetchModifyGroup.pending, (state) => {
        state.fetchModifyGroupStatus = "loading"
      })
      .addCase(fetchModifyGroup.fulfilled, (state) => {
        state.fetchModifyGroupStatus = "success"
      })
      .addCase(fetchModifyGroup.rejected, (state) => {
        state.fetchModifyGroupStatus = "error"
      })
      .addCase(fetchDeleteGroup.pending, (state) => {
        state.fetchDeleteGroupStatus = "loading"
      })
      .addCase(fetchDeleteGroup.fulfilled, (state) => {
        state.fetchDeleteGroupStatus = "success"
      })
      .addCase(fetchDeleteGroup.rejected, (state, action) => {
        state.fetchDeleteGroupStatus = "error"
        state.fetchDeleteGroupError = action.error.message  
      })
  }
})


export const {
  idleOrganizationGroups
} = organizationGroupsSlice.actions

export default organizationGroupsSlice.reducer
