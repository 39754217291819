import React from "react"
import { Trans, useTranslation } from "react-i18next"
import { useEffect, useState } from "react"
import { Group, fetchCreateGroup, fetchModifyGroup, fetchDeleteGroup, fetchGroups, idleOrganizationGroups } from "./organizationGroupsSlice"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../store"
import { Button, Form, Input, Modal, ColorPicker, Table, message } from "antd"
import useSwitchStatus from '../../utils/hooks/useSwitchStatus'
import { ColumnsType } from "antd/es/table"
import { AiFillDelete, AiFillEdit } from "react-icons/ai"
import { Organization } from "../OrganizationsList/organizationListSlice"
import './OrganizationGroups.scss'

interface Props {
  organization: Organization
}


function OrganizationGroups({ organization }: Props) {
  const { t } = useTranslation('organization')
  const dispatch:AppDispatch = useDispatch()
  const [groupToAdd, setGroupToAdd] = useState(false)
  const [groupToModify, setGroupToModify] = useState<Group>()
  const [groupToDelete, setGroupToDelete] = useState<Group>()
  const [addGroupForm] = Form.useForm()
  const [modifyGroupForm] = Form.useForm()
  const groups = useSelector((state:RootState) => state.organizationGroups.groups)
  const fetchAddGroupStatus = useSelector((state:RootState) => state.organizationGroups.fetchCreateGroupStatus)
  const fetchAddGroupError = useSelector((state:RootState) => state.organizationGroups.fetchCreateGroupError)
  const fetchModifyGroupStatus = useSelector((state:RootState) => state.organizationGroups.fetchModifyGroupStatus)
  const fetchModifyGroupError = useSelector((state:RootState) => state.organizationGroups.fetchModifyGroupError)
  const fetchDeleteGroupStatus = useSelector((state:RootState) => state.organizationGroups.fetchDeleteGroupStatus)
  const fetchDeleteGroupError = useSelector((state:RootState) => state.organizationGroups.fetchDeleteGroupError)

  useEffect(() => {
    updateGroups()
    return () => {
      dispatch(idleOrganizationGroups())
    }
  }, [])

  function updateGroups() {
    dispatch(fetchGroups(organization.id))
  }

  function addGroup(values: any) {
    dispatch(fetchCreateGroup({
      organizationId: organization.id,
      group: {
        participants: [],
        name: values['name'],
        color: values['color']?.toHexString() || "#224160",
      }
    }))
  }

  function modifyGroup(values: any) {
    if (groupToModify) {
      dispatch(fetchModifyGroup({
        organizationId: organization.id,
        groupId: groupToModify.id,
        group: { 
          participants: [],
          name: values['name'],
          color: values['color'].toHexString?.() || groupToModify.color,
        }
      }))
    }
  }

  function deleteGroup() {
    if (groupToDelete) {
      dispatch(fetchDeleteGroup({
        organizationId: organization.id,
        groupId: groupToDelete.id,
      }))
    }
  }

  useSwitchStatus(
    fetchAddGroupStatus,
    () => {
      message.success(t('Group has been added successfully.'))
      setGroupToAdd(false)
      addGroupForm.resetFields()
      updateGroups()
    },
    () => {
      switch (fetchAddGroupError) {
        default:  message.error(t('An error occured.', { ns: 'common' }))
      }
    }
  )

  useSwitchStatus(
    fetchModifyGroupStatus,
    () => {
      message.success(t('Group has been modified from the organization successfully.'))
      setGroupToModify(undefined)
      updateGroups()
    },
    () => {
      switch (fetchModifyGroupError) {
        case 'GROUP_NOT_FOUND':
          message.error(t(
            'Could not modify group. This group does no longer exist.',
          ))
          break
        default:  message.error(t('An error occured.', { ns: 'common' }))
      }
    }
  )

  useSwitchStatus(
    fetchDeleteGroupStatus,
    () => {
      message.success(t('Group has been deleted from the organization successfully.'))
      setGroupToDelete(undefined)
      updateGroups()
    },
    () => {
      switch (fetchDeleteGroupError) {
        case 'GROUP_NOT_FOUND':
          message.error(t(
            'Could not delete group. This group does no longer exist.',
          ))
          break
        default:  message.error(t('An error occured.', { ns: 'common' }))
      }
    }
  )


  const columns: ColumnsType<Group> = [
    {
      title: t('Name'),
      render: (group: Group) => (
        <div className="group-name-container">
          <div className="group-colour" style={{ backgroundColor: group.color }}/>
          <div>{group.name}</div>
        </div>
      )
    },
    {
      title: t('Actions'),
      render: (group: Group) => (
        <>
          <Button
            type='link'
            size='small' 
            onClick={() => { 
              setGroupToModify(group)
              modifyGroupForm.setFieldsValue({
                name: group.name,
                color: group.color,
              })
            }}
          >
            <AiFillEdit/>
          </Button>
          <Button type='link' size='small' danger onClick={() => setGroupToDelete(group)}><AiFillDelete/></Button>
        </>
      )
    },
  ]


  return (
    <>
      <div className='mb-1rem d-flex d-flex-middle g-1em'>
        <Button 
          type='primary'
          size='small'
          onClick={() => setGroupToAdd(true)}
        >
          {t('Add a group')}
        </Button>
      </div>

      <Table columns={columns} dataSource={groups} pagination={false} />

      <Modal
        title={t('Add a group')}
        open={!!groupToAdd}
        okText={t('Add')}
        onOk={() => addGroupForm.submit()}
        confirmLoading={fetchAddGroupStatus === "loading"}
        cancelText={t('Cancel', { ns: 'common' })}
        onCancel={() => {
          setGroupToAdd(false)
          addGroupForm.resetFields()
        }}
        destroyOnClose={true}
      >
        <Form
          form={addGroupForm}
          autoComplete="off"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={addGroup}
        >
          <Form.Item
            label={t('Name')}
            name="name"
            rules={[{ required: true, message: t('Please input a name') || 'Please input a name' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={t('Color')}
            name="color"
          >
            <ColorPicker format="hex" defaultValue="#224160" />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title={t('Modify group')}
        open={!!groupToModify}
        okText={t('Modify', { ns: 'common' })}
        onOk={() => modifyGroupForm.submit()}
        confirmLoading={fetchModifyGroupStatus === "loading"}
        cancelText={t('Cancel', { ns: 'common' })}
        onCancel={() => {setGroupToModify(undefined)}}
        destroyOnClose={true}
      >
        <Form
          form={modifyGroupForm}
          autoComplete="off"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={modifyGroup}
        >
          <Form.Item
            label={t('Name')}
            name="name"
            rules={[{ required: true, message: t('Please input a name') || 'Please input a name' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={t('Color')}
            name="color"
          >
            <ColorPicker format="hex" defaultValue="#224160" />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title={t('Delete group')}
        open={!!groupToDelete}
        okText={t('Delete', { ns: 'common' })}
        onOk={deleteGroup}
        confirmLoading={fetchDeleteGroupStatus === "loading"}
        cancelText={t('Cancel', { ns: 'common' })}
        onCancel={() => setGroupToDelete(undefined)}
        destroyOnClose={true}
      >
        <p>
          <Trans
            i18nKey="DELETE_GROUP"
            shouldUnescape={true}
            ns="organization"
            values={{ name: groupToDelete?.name }}
            components={{ bold: <strong /> }}
          />
        </p>
      </Modal>
    </>
  )
}

export default OrganizationGroups
